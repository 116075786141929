<template>
  <v-container>
    <v-row>
      <v-col>
        <div>
          <h2 class="overline mt-2 ml-4 grey--text d-flex">Establishments</h2>
          <v-data-table
            class="ml-4 mr-4 mt-4"
            dense
            v-if="establishments"
            :options="{ itemsPerPage: 100 }"
            :headers="
              [
                { name: 'Id', value: 'id' },
                { name: 'Nombre', value: 'name' },
                { name: 'Hectáreas Físicas', value: 'hectares' },
                { name: 'Region Id', value: 'regionId' },
                { name: 'Nombre de Region', value: 'regionName' },
              ].map((i) => {
                return { text: i.name, value: i.value };
              })
            "
            :items="establishments"
            :sort-by="'name'"
            @click:row="handleRowClick"
          ></v-data-table>

          <div class="d-flex ml-4 mr-4 mt-4">
            <div>
              <v-icon right class="mr-2 mt-2"> mdi-checkbox-plus </v-icon>
            </div>
            <v-text-field
              v-model="singleEstablishment.name"
              label="Nombre"
              class="managrx-input mr-2"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.number="singleEstablishment.hectares"
              label="Hectáreas"
              class="managrx-input mr-2"
              type="number"
              outlined
              dense
            ></v-text-field>

            <v-select
              outlined
              dense
              label="Region"
              v-model="singleEstablishment.region"
              :items="regions"
              item-text="name"
              item-value="id"
            >
            </v-select>
            <v-btn
              color="blue white--text"
              class="ml-2"
              @click="saveNewEstablishment"
              depressed
            >
              Guardar nuevo Establecimiento</v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import { postRequest } from "@/managrx/serverCall";

export default {
  //
  computed: {
    ...mapGetters(["establishments", "regions"]),
  },

  mounted() {
    this.$store.dispatch("getEstablishments");
  },
  methods: {
    handleRowClick(e) {
      this.$router.push({
        path: `/establishment/${e.id}`,
      });
    },
    async saveNewEstablishment() {
      let payload = {
        hectares: this.singleEstablishment.hectares,
        regionId: this.singleEstablishment.region,
        name: this.singleEstablishment.name,
      };
      let response = await postRequest("/establishment", payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$store.dispatch("getEstablishments");
        this.singleEstablishment = {
          hectares: 0,
          region: {},
          name: "",
        };
      }
    },
  },
  data() {
    return {
      singleEstablishment: {
        hectares: 0,
        region: {},
        name: "",
      },
    };
  },
};
</script>
