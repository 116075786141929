var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('div',[_c('h2',{staticClass:"overline mt-2 ml-4 grey--text d-flex"},[_vm._v("Establishments")]),(_vm.establishments)?_c('v-data-table',{staticClass:"ml-4 mr-4 mt-4",attrs:{"dense":"","options":{ itemsPerPage: 100 },"headers":[
              { name: 'Id', value: 'id' },
              { name: 'Nombre', value: 'name' },
              { name: 'Hectáreas Físicas', value: 'hectares' },
              { name: 'Region Id', value: 'regionId' },
              { name: 'Nombre de Region', value: 'regionName' } ].map(function (i) {
              return { text: i.name, value: i.value };
            }),"items":_vm.establishments,"sort-by":'name'},on:{"click:row":_vm.handleRowClick}}):_vm._e(),_c('div',{staticClass:"d-flex ml-4 mr-4 mt-4"},[_c('div',[_c('v-icon',{staticClass:"mr-2 mt-2",attrs:{"right":""}},[_vm._v(" mdi-checkbox-plus ")])],1),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"label":"Nombre","outlined":"","dense":""},model:{value:(_vm.singleEstablishment.name),callback:function ($$v) {_vm.$set(_vm.singleEstablishment, "name", $$v)},expression:"singleEstablishment.name"}}),_c('v-text-field',{staticClass:"managrx-input mr-2",attrs:{"label":"Hectáreas","type":"number","outlined":"","dense":""},model:{value:(_vm.singleEstablishment.hectares),callback:function ($$v) {_vm.$set(_vm.singleEstablishment, "hectares", _vm._n($$v))},expression:"singleEstablishment.hectares"}}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Region","items":_vm.regions,"item-text":"name","item-value":"id"},model:{value:(_vm.singleEstablishment.region),callback:function ($$v) {_vm.$set(_vm.singleEstablishment, "region", $$v)},expression:"singleEstablishment.region"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"blue white--text","depressed":""},on:{"click":_vm.saveNewEstablishment}},[_vm._v(" Guardar nuevo Establecimiento")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }